import { Link, useLocation } from 'react-router-dom'

import Logo from '../../assets/img/svg/logo.svg'
import LogoBlack from '../../assets/img/svg/logo-black.svg'

const Footer = () => {
  const { pathname } = useLocation()
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__inner">
          <a className="footer__logo" href="#">
            <img
              className="footer__logo-img"
              width="172"
              height="38"
              src={pathname === '/support' ? LogoBlack : Logo}
              alt="logo iocn"
            />
          </a>

          {pathname !== '/support' && (
            <nav className="footer__nav">
              <ul className="footer__list">
                <li className="footer__item">
                  <Link className="footer__link" to="/support">
                    About project
                  </Link>
                </li>
                <li className="footer__item">
                  <a className="footer__link" href="https://lucem.fund" target="_blank" rel="noreferrer">
                    Participant of the Lucem Fund project
                  </a>
                </li>
                <li className="footer__item">
                  <Link className="footer__link" to="/support">
                    Swap Usage Rules
                  </Link>
                </li>
                <li className="footer__item">
                  <Link className="footer__link" to="/support">
                    Listing of new tokens
                  </Link>
                </li>
              </ul>

              <ul className="footer__list">
                <li className="footer__item">
                  <Link className="footer__link" to="/support">
                    Liquidity Provider
                  </Link>
                </li>
                <li className="footer__item">
                  <Link className="footer__link" to="/support">
                    Road map
                  </Link>
                </li>
                <li className="footer__item">
                  <Link className="footer__link" to="/support">
                    Support
                  </Link>
                </li>
                <li className="footer__item">
                  <Link className="footer__link" to="/support">
                    Partners
                  </Link>
                </li>
              </ul>

              <ul className="footer__list">
                <li className="footer__item">
                  <Link className="footer__link" to="/support">
                    Pool
                  </Link>
                </li>
                <li className="footer__item">
                  <Link className="footer__link" to="/support">
                    Term of Use
                  </Link>
                </li>
                <li className="footer__item">
                  <Link className="footer__link" to="/support">
                    Fee
                  </Link>
                </li>
                <li className="footer__item">
                  <Link className="footer__link" to="/support">
                    Contact to Sale
                  </Link>
                </li>
              </ul>
            </nav>
          )}
        </div>
      </div>
    </footer>
  )
}

export default Footer
